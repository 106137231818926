@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

::selection {
  background: #138025;
  color: white;
}

html {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
